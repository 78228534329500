<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link>
                </p>
                </v-col>
            </v-row> -->
        <v-row justify="center" class="py-5 mt-2">
            <v-col cols="12" sm="10" md="8" lg="6" xl="6" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Whois Lookup</h1>
                <v-form @submit.prevent="search" @keyup.enter.native="search">
                    <v-text-field v-model="searchQuery" ref="searchInput" dense solo color="purple darken-2" placeholder="Domain" hint="Enter a domain name to look up nameserver information">
                        <template #prepend-inner>
                            <font-awesome-icon :icon="['fas', 'search']" fixed-width color="purple darken-2"/> <!-- style="font-size: 20px;" -->
                        </template>
                    </v-text-field>
                </v-form>
                <!-- <p class="text-caption" color="grey--text text-lighten-2">Whois</p> -->
                <v-card class="mt-2" v-if="whois">
                    <v-toolbar :color="primaryColor" dense flat dark>
                        <v-toolbar-title>
                            Whois
                        </v-toolbar-title>
                        <v-spacer/>
                        <v-btn icon @click="refresh" v-if="searchQuery">
                            <font-awesome-icon :icon="['far', 'sync-alt']" fixed-width/>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <!-- <p class="text-overline mb-0">Registrar</p> -->
                        <template v-if="whois">
                        <p class="mb-0 pb-0">
                            Registrar:
                            <template v-if="registrarURL">
                                <a :href="registrarURL" target="_blank">{{ registrarName }}</a>
                            </template>
                            <template v-if="!registrarURL">
                                {{ registrarName }}
                            </template>
                            <template v-if="whois.text">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click="whoisDialog = true" v-bind="attrs" v-on="on">
                                        <font-awesome-icon :icon="['fas', 'info-circle']" class="blue--text"/>
                                    </v-btn>
                                </template>
                                See full text
                            </v-tooltip>
                            <v-dialog v-model="whoisDialog">
                                <v-card elevation="4">
                                    <v-toolbar short flat color="white">
                                        <v-toolbar-title>Whois result for {{ searchQuery }}</v-toolbar-title>
                                    </v-toolbar>
                                    <v-form class="pa-5" onSubmit="return false;">
                                    <v-textarea :value="whois.text" rows="10" outlined/>
                                    </v-form>
                                </v-card>
                            </v-dialog>
                            </template>
                        </p>
                        </template>
                    </v-card-text>
                </v-card>
                <v-alert v-if="error" type="error" class="mt-6">
                    We were not able to look up the information at this time.
                </v-alert>
            </v-col>
        </v-row>
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
// import RealmList from '@/components/domain-dashboard/RealmList.vue';

export default {
    components: {
        // RealmList,
    },
    data: () => ({
        account: null,
        domain: null,
        error: null,
        errorTimeout: null,
        searchQuery: null,
        searchTimestamp: null,
        notFound: false,
        notFoundTimeout: null,
        whois: null,
        whoisDialog: false,
        status: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
        }),
        accountName() {
            return this.account?.name ?? '';
        },
        registrarName() {
            return this.whois?.info?.Registrar?.join(' / ') ?? 'Unknown';
        },
        registrarURL() {
            try {
                const list = this.whois?.info?.['Registrar URL'];
                if (Array.isArray(list) && list.length > 0) {
                    const inputURL = new URL(list[0]);
                    return inputURL.toString();
                }
                return null;
            } catch (err) {
                return null;
            }
        },
        registrarDnsNameservers() {
            return this.whois?.info?.['Name Server'] ?? [];
        },
        // TODO: move this getter to vuex so we don't have to redefine it everywere... we'll just use mapGetters
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        reset() {
            this.error = false;
            if (this.errorTimeout) {
                clearTimeout(this.errorTimeout);
                this.errorTimeout = null;
            }
            this.notFound = false;
            if (this.notFoundTimeout) {
                clearTimeout(this.notFoundTimeout);
                this.notFoundTimeout = null;
            }
            this.whois = null;
        },
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).self.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async search(options = {}) {
            if (Number.isInteger(this.searchTimestamp) && this.searchTimestamp + 500 > Date.now()) {
                return;
            }
            this.searchTimestamp = Date.now();
            if (!this.searchQuery) {
                this.activate('searchInput');
                return;
            }
            try {
                if (this.$route.query.q !== this.searchQuery) {
                    // update URL in address bar
                    this.$router.replace({ name: 'account-view-whois', params: { accountId: this.$route.params.accountId }, query: { q: this.searchQuery } });
                }
                this.reset();
                this.$store.commit('loading', { search: true });
                const { refresh = false } = options;
                const response = await this.$client.account(this.$route.params.accountId).domain.checkWhois({ domain: this.searchQuery, refresh });
                console.log(`checkWhois response ${JSON.stringify(response)}`);
                if (response) {
                    this.whois = response;
                } else {
                    this.notFound = true;
                    this.notFoundTimeout = setTimeout(() => { this.notFound = false; }, 15000); // clear message in 15 seconds
                }
            } catch (err) {
                console.error('failed to search', err);
                this.error = true;
                this.errorTimeout = setTimeout(() => { this.error = false; }, 15000); // clear message in 15 seconds
            } finally {
                this.$store.commit('loading', { search: false });
            }
        },
        async refresh() {
            return this.search({ refresh: true });
        },
    },
    created() {
        // listen for search events coming from any component (but specifically the navbar search button)
        this.$bus.$on('search', (q) => {
            this.searchQuery = q;
            this.$nextTick(() => {
                setTimeout(() => { this.activate('searchInput'); }, 1);
            });
            if (q) {
                this.search();
            } else {
                this.reset();
            }
        });
    },
    mounted() {
        this.loadAccount();
        this.$nextTick(() => {
            setTimeout(() => { this.activate('searchInput'); }, 1);
        });
        this.searchQuery = this.$route.query.q ?? '';
        if (this.searchQuery) {
            this.search();
        }
    },
};
</script>
